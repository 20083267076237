import React from "react"

const Label = ({ children, name, className, large = false }) => {
  // return <p className={`text-sm font-medium mt-3 mb-2 ${className}`}>{children}</p>

  return (
    <div className={containerStyle({ large }) + className}>
      <div className={labelStyle({ large })}>{name.replace(":", "")}</div>
      {children}
    </div>
  )
}

export default Label

const containerStyle = ({ large }) => `
  flex
  flex-col
  ${large ? "text-base" : "text-sm"}
  flex-1
`

const labelStyle = ({ large }) => `
  ${large ? "text-base" : "text-sm"}
`
