import React from "react"

const SButton = ({ children, onClick, className }) => {
  return (
    <div className={`mt-4 h-9 py-1 px-4 cursor-pointer text-gray-500 text-xs font-medium flex-shrink ${className}`} onClick={onClick}>
      {children}
    </div>
  )
}

export default SButton
