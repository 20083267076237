import { useState } from "react"
import { useDispatch } from "react-redux"
import { login } from "src/api"
import { setSessionToken, setUser, setCompany } from "../../store/account/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const execute = async (username, password) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("LOGIN_USER"))

      setLoading(true)
      setError(undefined)
      const data = await login(username, password)

      dispatch(setSessionToken(data.value))
      dispatch(setUser(data.user))
      dispatch(setCompany(data.company))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, login: execute }
}
